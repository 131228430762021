// src/features/counterSlice.js
import { createSlice } from "@reduxjs/toolkit"

// const initialState = { viewCatalogModalLiveChat: false }
const initialState = {
  livechatData: {
    viewCatalogModalLiveChatData: {
      modalView: false,
      productList : []
    }
  }
}

export const whatsappSlice = createSlice({
  name: "whatsappSlice",
  initialState,
  reducers: {
    setViewCatalogModalLiveChat: (state, action) => {
      state.livechatData.viewCatalogModalLiveChatData.modalView = action.payload
    },
    setLivechatCatalogModalProducts: (state, action) => {
      state.livechatData.viewCatalogModalLiveChatData.productList = action.payload
    }   
  }
})

export const { setViewCatalogModalLiveChat, setLivechatCatalogModalProducts } = whatsappSlice.actions

export default whatsappSlice.reducer
